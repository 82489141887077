.color4 {
  color: #41533b;
}

.color4-back {
  background-color: #8c9171;
}

.color1-back {
  background-color: #44aaaa;
}

.color6-back {
  background-color: #41533b;
}

.nodecoration {
  text-decoration: none; /* No underline */
}

.page-container {
  display: grid;
  /* flex-direction: row; is default, so you can omit it */
  grid-template-columns: 1fr 2fr;
  justify-content: center; /* Center buttons horizontally */
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

.page-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.2; /* Adjust opacity for desired transparency */
  z-index: 0; /* Ensures the overlay is below the buttons */
}

.page-left-container {
  display: grid;
  grid-template-rows: 1fr 4fr; /* Top is 1/3, bottom is 2/3 */
  border-right: 2px solid rgba(255, 255, 255, 0.2); /* Subtle divider line */
  position: relative;
  padding: 20px;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black */
  z-index: 2; /* Ensure it's above the overlay */
  overflow-y: auto;
  border-right: 2px solid rgba(255, 255, 255, 0.2); /* Subtle divider line */
  border-radius: 8px;
  margin-left: 20px;
}

.page-right-container {
  display: flex;
  flex-direction: column; /* Stack components vertically */
  justify-content: space-between; /* Ensure both sections take up the full height */
  height: 80vh; /* Full height of the viewport */
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Adjust the background color as needed */
  border-radius: 8px;
  overflow-y: auto;
  margin-right: 10px;
  z-index: 2;
}

.toggle-button-container {
  position: absolute;
  top: 10px; /* Adjust as necessary for top spacing */
  right: 20px; /* Adjust as necessary for right spacing */
  z-index: 10000; /* Ensures the button stays on top of other content */
  color: antiquewhite;
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  align-items: center;
  align-content: center;
}

.fixed-help-button {
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 10000;
  width: 30px;
  border-radius: 20px;
}

.access-deny {
  color: #fff; /* Text color */
  z-index: 100000;
  font-size: 30px;
  align-items: center;
  justify-content: center;
}
