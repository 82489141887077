.home-background-image {
  background-image: url("../../../public/frontpage.png"); /* Path to your background image */
  z-index: 1;
}

.Calendar {
  height: 100%; /* Full container height */
  width: 100%;
}

.calendar-page-container {
  justify-content: center; /* Center buttons horizontally */
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  height: 80vh; /* Full viewport height */
  padding: 0;
  display: flex;
}

.calendar-container {
  width: 90%; /* or any specific width you prefer */
  max-width: 1200px; /* Sets a max-width for larger screens */
  height: 100%; /* Full height of its container */
  z-index: 1000;
  border-radius: 10px; /* Rounded corners for the calendar */
  overflow: hidden; /* Ensures child elements do not overflow the rounded corners */
  background-color: white;
}

.rbc-month-view {
  background-color: white;
}

.rbc-day-bg {
  background-color: white !important; /* Overriding any other styles */
}

.rbc-header {
  background-color: #f0f0f0; /* Light grey background for headers */
  border-radius: 10px; /* Rounded corners for each header cell */
}

.rbc-today {
  background-color: #e3f2fd; /* Light blue background for today's date */
  border-radius: 10px; /* Rounded corners for today's date cell */
}

.rbc-toolbar-label {
  font-size: 20px; /* Larger font size for current month */
  font-weight: bold; /* Bold for emphasis */
  color: #333; /* Dark color for better visibility */
}

.rbc-btn-group button {
  color: #333; /* Style navigation buttons to match the header */
  font-weight: bold;
}
