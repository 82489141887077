.grid-item {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

.grid-item img {
  height: 180px;
  object-fit: cover;
}

.grid-item p {
  color: #fff;
  text-align: center;
}

.grid-item-link {
  color: #fff;
}

.range-visit-map-container {
  max-width: 100%;
  cursor: pointer;
}

.delete-button {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff0000;
  color: #fff;
  padding: 5px 8px;
  border-radius: 50%;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.delete-button:hover {
  opacity: 1;
}
