.search-box-container {
  position: relative;
  height: 400px; /* Set to the desired map height */
  width: 100%;
}

.search-input {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  width: calc(100% - 20px); /* Adjust padding based on left and right offsets */
  height: 30px;
  z-index: 2; /* Higher than the map */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent */
  border: none;
  padding: 8px;
  border-radius: 4px;
}

.search-input:focus {
  background-color: rgba(255, 255, 255, 1); /* Opaque on focus */
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Positioned below the input field, adjust based on input height */
  left: 10px;
  width: calc(100% - 20px);
  z-index: 1000; /* Higher than the map */
  background-color: white; /* Dropdown background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: for better visibility */
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
}

.map {
  height: 100%;
  width: 100%;
}
