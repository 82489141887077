/* Base modal background styling */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    10,
    10,
    10,
    0.86
  ); /* Semi-transparent black background */
  z-index: 10000;
}

/* Modal card styling for centering and aesthetics */
.modal-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 600px; /* Max width for the modal */
  background: #2c3e50; /* Dark background for the modal */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 10000;
}

/* Header styling */
.modal-card-head {
  padding: 12px 20px;
  background: #34495e; /* Darker shade for header */
  border-bottom: 1px solid #2c3e50;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-card-title {
  margin: 0;
  font-weight: bold;
  color: #ecf0f1; /* Light color for text */
}

/* Close button enhanced visuals */
.delete {
  border: none;
  background: none;
  font-size: 24px;
  color: #ecf0f1; /* Light color for icons */
  cursor: pointer;
  background-color: brown;
}

/* Body of the modal card */
.modal-card-body {
  padding: 20px;
  background-color: #34495e;
  color: #ecf0f1; /* Ensuring text is readable on a dark background */
}

/* Footer styling */
.modal-card-foot {
  padding: 12px 20px;
  background: #34495e; /* Keeping footer consistent with header */
  display: flex;
  justify-content: space-between; /* Ensures buttons are spaced out evenly */
}

/* Buttons styling */
.add-firearm-button {
  flex: 1; /* Each button takes up equal space */
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5f5f5; /* Light text for contrast on dark buttons */
  margin-right: 10px; /* Spacing between buttons */
}

.add-firearm-button:last-child {
  margin-right: 0; /* No margin on the right for the last button */
}

.add-firearm-button.is-success {
  background-color: #27ae60; /* Vivid green for success buttons */
}

.button.is-success:hover {
  background-color: #2ecc71; /* Lighter green on hover */
}

.button:hover {
  background-color: #95a5a6; /* Grey for hover on the regular button */
}

/* Input styling */
.input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #34495e;
  border-radius: 4px;
  background: #2c3e50; /* Dark input backgrounds */
  color: #ecf0f1; /* Light text color for readability */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  color: #ecf0f1; /* Light label color for contrast */
}

.cancel-button {
  background-color: darkred;
}

.cancel-button:hover {
  background-color: crimson;
}

.select {
  width: 100%;
}
.select select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #34495e;
  border-radius: 4px;
  background: #2c3e50; /* Dark input backgrounds */
  color: #ecf0f1; /* Light text color for readability */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  appearance: none; /* Disables native OS UI */
}

/* Style for the arrow indicator of the select box */
.select::after {
  content: "▼";
  position: absolute;
  top: 15px;
  right: 15px;
  pointer-events: none;
  color: #ecf0f1;
}

/* Optional: Style to add focus effect similar to input */
.select select:focus {
  outline: none;
  border-color: #3498db;
}

/* Style for options within the select */
option {
  background: #34495e; /* Dark background for options */
  color: #ecf0f1; /* Light text color for readability */
}

.notification {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0; /* Stretch across the container */
  z-index: 2000000; /* Ensure it's above other content */

  /* Notification styling */
  color: #d8000c;
  background-color: #ffbaba;
  padding: 40px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center; /* Center the text */
}

.input-group {
  display: flex;
  align-items: center; /* Ensures contents are vertically aligned */
}

.input-group-prepend {
  background: #34495e; /* Dark background for options */
  padding: 0 8px; /* Padding on left and right */
  margin-right: -1px; /* Aligns tightly with the input field */
  color: #f0e7e7; /* Text color */
  display: flex;
  align-items: center; /* Centers the dollar sign vertically */
  margin-bottom: 10px;
}

.input {
  flex: 1; /* Makes the input take up the remaining space */
  border-radius: 0; /* Removes the border-radius if you want a square look */
}

.input-notes {
  width: 100%; /* Adjust the width as needed */
  height: 20vh; /* Adjust the height as needed */
}
