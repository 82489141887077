.carousel-slide {
    position: absolute;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease;
    opacity: 0;
  }
  
  .carousel-slide.active {
    opacity: 1; /* Show the active slide */
  }
  
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust transparency */
    z-index: 1; /* Overlay remains above background image but below text */
  }
  
  .carousel-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust width to control the text area */
    z-index: 2; /* Keeps text above the overlay */
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
    font-size: 2em; /* Adjust based on your design needs */
  }
  
  .arrow-left,
  .arrow-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 50%;
  }
  
  .arrow-left {
    left: 20px;
  }
  
  .arrow-right {
    right: 20px;
  }
  
  .arrow {
    color: #fff;
    font-size: 2em;
  }