.faq-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  padding: 20px;
  color: white;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  position: relative;
}

.page-background.faq-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/frontpage.png"); /* Path to your background image */
  z-index: 1;
  background-size: cover;
  background-position: center;
}

.faq-container {
  height: 75vh; /* Set a fixed height */
  width: 80%; /* Make the container width responsive */
  margin-top: 20px; /* Add margin to separate from the top */
  padding: 20px;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  border-radius: 10px;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling */
  box-sizing: border-box; /* Ensure padding doesn’t affect the width */
  text-align: left; /* Align text to the left */
}

.faq-container h2 {
  font-size: 1.8rem;
}
.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.faq-item p {
  color: #d3d3d3;
  font-size: 1.1rem;
}
