.rangevisit-dashboard-background-image {
  background-image: url("../../../../public/rangevisit.png"); /* Path to your background image */
}

.range-visit-dashboard-custom-page-container {
  grid-template-columns: 1fr 1fr;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Creates a responsive grid */
  grid-gap: 20px; /* Spacing between grid items */
  padding: 20px; /* Padding around the entire grid */
  z-index: 1;
  color: #fff; /* Ensure contrast with the background */
}

.inputs-container {
  display: flex;
  position: relative;
  flex-direction: column; /* Align inputs vertically */
  gap: 10px; /* Consistent spacing between elements */
  border-radius: 5px;
  padding: 5px;
  z-index: 2;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background for readability */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow */
  color: #fff; /* Ensure contrast with the background */
}

.custom-datepicker {
  font-size: 18px; /* Adjust the font size */
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box; /* Include padding in the width calculation */
  outline: none;
  background-color: #fff;
  margin-bottom: 10px;
}

.custom-datepicker:focus {
  border-color: #007bff; /* Change border color on focus */
  margin-bottom: 0px;
}

.duration-container {
  display: flex; /* Aligns children (input and label) in a row */
  align-items: center; /* Center-align items vertically */
  width: 100%;
  gap: 5px; /* Space between input and label */
}

.duration-input {
  margin-right: 5px; /* Adds a small space between the input and the label */
  padding: 8px;
  font-size: 16px; /* Ensures the input text is easily readable */
  width: 60%; /* Adjust width for the input */
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.duration-label {
  font-size: 16px; /* Match font size with input */
  width: 100%;
}

.map-container {
  border-radius: 8px;
  margin-top: 5px;
  width: 100%; /* Makes the image take the full width of the container */
  height: auto; /* Keeps the image aspect ratio */
  z-index: 2;
  padding: 10px;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background for readability */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow */
  color: #fff; /* Ensure contrast with the background */
}

.add-visit-button {
  text-transform: uppercase;
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  display: block; /* Ensure it takes the full width available if necessary */
  width: 100%; /* Adjust width as per design requirement */
  padding: 10px 20px; /* Padding for better touch area */
  z-index: 2; /* Keeps it above the overlay */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent button background */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  color: #fff; /* Text color */
  cursor: pointer;
}

.add-visit-button:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Darken on hover */
}
