.accessory-list-background-image {
  background-image: url("../../../../public/maintain.png"); /* Path to your background image */
}

.accessory-list-page-container {
  overflow: hidden; /* Prevents the scroll bar from appearing */
  background-color: rgba(0, 0, 0, 0.7); /* Light background color */
}

.accessory-search-bar {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: white;
  color: black;
  border-radius: 10px;
}

.fixed-height-columns-list-view {
  height: 90vh; /* 80% of the viewport height */
  justify-content: flex-start; /* Ensures that all content is aligned to the top */
  display: flex;
  flex-direction: column;
}

.fixed-add-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.accessory-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  width: 100%;
  z-index: 5;
}

.accessory-header > div {
  margin-top: 10px;
  font-weight: bold;
  background-color: #f4f4f4; /* Light grey background for headers */
  z-index: 50;
}
