.rangevisit-dashboard-background-image {
  background-image: url("../../../../public/rangevisit.png"); /* Path to your background image */
}

.rangevisit-detail-custom-page-container {
  grid-template-columns: 1fr 1fr;
}

.range-visit-detail-item {
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent for readability */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  z-index: 2;
}

.range-visit-detail-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.range-visit-detail-notes {
  margin-top: 5px; /* Add some space above the notes */
  color: #fff; /* Adjust color as needed */
}

.range-visit-detail-list-delete-button {
  border: none;
  background-color: rgba(255, 0, 0, 0.6); /* Semi-transparent red button */
  color: #fff; /* White text */
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border-radius: 50%;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  margin-right: 10px;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow */
  z-index: 2;
}

.range-visit-detail-list-firearm {
  font-weight: bold;
  color: #fff; /* White text */
  font-size: larger;
  color: lightyellow;
}

.range-visit-detail-list-value {
  font-weight: normal;
  color: lightcoral; /* White text */
}

.button-container .button {
  flex: 1;
  text-align: center;
  margin-right: 5px; /* Adds margin to the right of each button */
}

.button-container .button:last-child {
  margin-right: 0; /* Removes margin from the right of the last button */
}

.edit-button {
  text-transform: uppercase;
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  display: block; /* Ensure it takes the full width available if necessary */
  width: 100%; /* Adjust width as per design requirement */
  height: 5vh;
  padding: 10px 20px; /* Padding for better touch area */
  margin-top: 20px; /* Space above the button */
  z-index: 2; /* Keeps it above the overlay */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent button background */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  color: #fff; /* Text color */
  cursor: pointer;
}

.edit-button:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Darken on hover */
}

.custom-page-right-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensures that all content is aligned to the top */
  position: relative; /* Needed for absolute positioning context */
}

.inputs-container {
  display: flex;
  flex-direction: column; /* Align inputs vertically */
  gap: 10px; /* Consistent spacing between elements */
  margin-bottom: 15px;
  z-index: 2;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background for readability */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow */
  color: #fff; /* Ensure contrast with the background */
}

.custom-datepicker {
  font-size: 18px; /* Adjust the font size */
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box; /* Include padding in the width calculation */
  outline: none;
  background-color: #fff;
}

.custom-datepicker:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.5); /* Subtle shadow for focused state */
}

.duration-container {
  display: flex; /* Aligns children (input and label) in a row */
  align-items: center; /* Center-align items vertically */
  width: 100%;
  gap: 5px; /* Space between input and label */
}

.duration-input {
  margin-right: 5px; /* Adds a small space between the input and the label */
  padding: 8px;
  font-size: 16px; /* Ensures the input text is easily readable */
  width: 60%; /* Adjust width for the input */
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
}

.duration-label {
  font-size: 16px; /* Match font size with input */
  width: 100%;
}

.info-container {
  background-color: rgba(0, 0, 0, 0.6); /* Black background with transparency */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  width: 100%;
  color: #f5f5f5; /* Light color text for contrast */
}

.info-container p {
  margin: 5px 0;
}

.info-link,
.info-link p {
  color: #8fbc8f; /* Army green color for the link */
  text-decoration: none; /* No underline */
  cursor: pointer; /* Indicates the text is clickable */
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.info-link:hover,
.info-link p:hover {
  color: #556b2f; /* Darker shade of green on hover */
  text-decoration: underline; /* Adding underline on hover for clarity */
}

.date,
.duration {
  font-weight: bold;
}

/* Additional styling for a hover effect that slightly lightens the background */
.info-container:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Slightly less transparent on hover */
}

.firearm-detail-section {
  margin-bottom: 20px;
  flex: 1; /* Each section takes equal space */
  display: flex;
  flex-direction: column;
}

.firearm-detail-section p {
  color: antiquewhite;
}

.firearm-detail-title {
  font-size: 18px;
  color: antiquewhite;
  margin-bottom: 10px;
  font-weight: bold;
}

.firearm-detail-gallery-grid {
  min-height: 50%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Creates a responsive grid */
  grid-gap: 10px; /* Space between items */
  padding: 10px;
}

.firearm-detail-gallery-item {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  border-radius: 8px; /* Optional: Rounds the corners of the images */
  background-color: white;
  align-content: center;
  cursor: pointer;
}

.firearm-detail-gallery-item:hover {
  transform: scale(1.02); /* Slightly enlarges the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  font-weight: bold;
}

.firearm-detail-gallery-add-image-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust size as needed */
  color: #fff; /* Text color */
  background-color: #00000060; /* Button background color, adjust as needed */
  border-radius: 8px; /* Consistent with gallery items */
  cursor: pointer;
}

.access-deny {
  color: #fff; /* Text color */
  z-index: 100000;
  font-size: 30px;
  align-items: center;
  justify-content: center;
}
