.home-background-image {
  background-image: url("../../../public/frontpage.png"); /* Path to your background image */
  z-index: 1;
}

.contact-page-container {
  justify-content: center; /* Center buttons horizontally */
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
  height: 80vh; /* Full viewport height */
  padding: 0;
  display: flex;
}

.contact-container {
  width: 90%; /* or any specific width you prefer */
  max-width: 1200px; /* Sets a max-width for larger screens */
  height: 100%; /* Full height of its container */
  z-index: 1000;
  border-radius: 10px; /* Rounded corners for the calendar */
  overflow: hidden; /* Ensures child elements do not overflow the rounded corners */
  background-color: white;
  color: cadetblue;
}
