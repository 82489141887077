.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    10,
    10,
    10,
    0.86
  ); /* Semi-transparent black background */
  z-index: 10000;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 600px; /* Max width for the modal */
  background: #2c3e50; /* Dark background for the modal */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 10000;
}
