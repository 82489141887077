.firearm-card {
  display: flex;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  align-items: flex-start;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 80%;
  max-width: 1200px;
}

.firearm-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.firearm-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.editable-field,
.firearm-info h2,
.firearm-info p {
  width: 100%;
  padding: 8px;
  margin: 3px 0;
  border-radius: 4px;
  border: none;
  outline: none;
}

/* .editable-field {
    border: 1px solid #ccc;
    display: block;
    color: #fff;
} */

.button {
  padding: 8px 16px;
  font-size: 0.9em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.2s;
  outline: none;
  margin-top: 10px;
}

.card-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.edit-button,
.save-button,
.cancel-button,
.delete-button {
  color: white;
  font-weight: bold;
}

.card .firearm-item-edit-button {
  background-color: #80c982;
  width: 20%;
  height: 20%;
  /* other styles */
}
.card .firearm-item-save-button {
  background-color: #2196f3;
  width: 80%;
  height: 80%;
  /* other styles */
}
.card .firearm-item-cancel-button {
  background-color: #f0ad4e;
  width: 80%;
  height: 80%;
  /* other styles */
}
.card .firearm-item-delete-button {
  background-color: #ad3931;
  width: 80%;
  height: 80%;
  /* other styles */
}
.edit-button:hover,
.save-button:hover,
.delete-button:hover,
.cancel-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.horizontal-card {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Distributes space between elements */
  /* more horizontal styles */
}

.horizontal-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.firearm-horizontal .card-image {
  flex: 0 0 auto;
  width: 30%; /* adjust based on your design */
}

.firearm-horizontal .card-content {
  flex: 1;
  padding: 20px;
}

.firearm-details p {
  margin-bottom: 5px;
}

.horizontal-layout {
  display: flex;
  flex-wrap: wrap; /* Ensures items wrap onto the next line if not enough space */
  gap: 10px; /* Adds space between items */
  align-items: center; /* Vertically aligns items in the middle */
  justify-content: space-around; /* Distributes space around items */
}

.firearm-detail-horizontal {
  white-space: nowrap; /* Prevents breaking of the text inside span */
}

.firearms-header,
.horizontal-layout {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  text-align: center;
  width: 100%;
}

.firearm-detail-horizontal {
  padding: 5px; /* Adjust padding as needed */
}

.firearms-header > div {
  margin-top: 10px;
  font-weight: bold;
  background-color: #f4f4f4; /* Light grey background for headers */
  z-index: 50;
}

.list-layout {
  width: 100%;
  z-index: 50;
}

.firearms-list-item {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  text-align: center;
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
  cursor: pointer; /* Changes the mouse cursor to indicate it's clickable */
  background-color: #efefef; /* Lightens the background on hover */
  z-index: 10;
}

.firearms-list-item > div {
  background-color: rgba(0, 0, 0, 0); /* Light grey background for headers */
  z-index: 0;
}

.firearms-list-item:hover {
  background-color: rgba(
    9,
    187,
    53,
    0.91
  ); /* Lightens the background on hover */
  transform: scale(1.01); /* Slightly enlarges the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  font-weight: bold;
}

.firearms-list-item-container {
  z-index: 10;
}

.image {
  height: 100%; /* Fixed height for all images */
  width: auto;
  overflow: hidden; /* Ensures the image stays within bounds */
  position: relative; /* Establishes a positioning context for pseudo elements or children */
  border-radius: 10px; /* Optional: Rounds the corners of the image container */
  object-fit: contain; /* Ensures the entire image is visible without cropping and maintains aspect ratio */
}

.firearm-image {
  max-height: 20vh; /* Fixed height for all images */
  min-height: 20vh; /* Fixed height for all images */
  width: auto; /* Ensures the image covers the width of the container */
  object-fit: contain; /* Ensures the entire image is visible without cropping and maintains aspect ratio */
  display: block; /* Remove extra space below the image */
}

.card-style {
  max-width: 10vw;
}

.card-style:hover {
  transform: scale(1.02); /* Slightly enlarges the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  cursor: pointer; /* Changes the mouse cursor to indicate it's clickable */
  border: 2px solid #ffd700; /* Highlight with a gold border */
}

.nodecoration {
  text-decoration: none; /* No underline */
  color: #053613;
}
