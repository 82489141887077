.custom-page-container {
  overflow: hidden; /* Prevents the scroll bar from appearing */
  background-color: rgba(0, 0, 0, 0.7); /* Light background color */
}
.firearm-page-background-image {
  background-image: url("../../../../public/firearm.png"); /* Path to your background image */
}

.fixed-height-columns {
  height: 90vh; /* 80% of the viewport height */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 10px; /* Optional padding */
  box-sizing: border-box; /* Padding included in the total height */
  border-radius: 8px; /* Rounded corners */
}

.fixed-height-columns-list-view {
  height: 90vh; /* 80% of the viewport height */
  justify-content: flex-start; /* Ensures that all content is aligned to the top */
  display: flex;
  flex-direction: column;
}

.fixed-add-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}

.list-layout,
.firearms-header {
  z-index: 5;
}

.firearm-search-bar {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: white;
  color: black;
  border-radius: 10px;
}

.firearm-display-switch-button {
  margin-bottom: 10px;
  margin-right: 10px;
}

.fixed-help-button {
  position: fixed;
  bottom: 10px;
  left: 30px;
  z-index: 10000;
  width: 30px;
}
