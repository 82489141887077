.accessory-list-item {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
  cursor: pointer; /* Changes the mouse cursor to indicate it's clickable */
  background-color: #efefef; /* Lightens the background on hover */
  z-index: 10;
}

.accessory-list-item > div {
  background-color: rgba(0, 0, 0, 0); /* Light grey background for headers */
  z-index: 0;
}

.accessory-list-item:hover {
  background-color: rgba(
    9,
    187,
    53,
    0.91
  ); /* Lightens the background on hover */
  transform: scale(1.01); /* Slightly enlarges the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  font-weight: bold;
}
