.App {
  text-align: center;
  height: 100vh;
  overflow: hidden; /* Prevents the scroll bar from appearing */
}

.top-bar {
  display: flex;
  overflow: hidden; /* Prevents the scroll bar from appearing */
  justify-content: space-between; /* Aligns one child to the start, the other to the end */
  align-items: center; /* Centers items vertically */
  z-index: 10;
}

.header-button-left {
  background-color: #f2f2f2; /* Light grey background */
  padding: 10px; /* Spacing inside the box */
  border-radius: 15px; /* Rounded corners */
  text-align: center; /* Center-align the text */
  display: inline-block; /* Make the h1 width only as wide as its content */
  margin: 20px; /* Margin around the h1 for some spacing */
  z-index: 10;
  overflow: hidden; /* Prevents the scroll bar from appearing */
}

.header-button-group-right {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px;
  z-index: 10;
  padding: 10px;
}

.header-button-container {
  display: flex;
  flex-direction: row; /* Align children horizontally */
  justify-content: center; /* Center children horizontally */
  align-items: flex-start; /* Align children to the start of the cross axis */
  gap: 20px; /* Optional: Adds space between the two sections */
  overflow: hidden; /* Prevents the scroll bar from appearing */
}

.header-button-link {
  color: #4caf50; /* Green text */
  text-decoration: none; /* No underline */
  font-weight: bold; /* Bold text */
  background-color: #c8e6c9; /* Light green background */
  padding: 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

/* Hover effect for the bottom link */
.header-button-link:hover {
  background-color: #81c784; /* Darker green background on hover */
  color: #fff; /* White text color on hover */
  text-decoration: underline; /* Optional: underline the text on hover */
}

.logout-button-link {
  z-index: 1001;
}
