.firearm-page-background-image {
  background-image: url("../../../../public/firearm.png"); /* Path to your background image */
}

.firearm-detail-box {
  padding: 0;
}
/* Card Styling */
.firearm-detail-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(0, 10, 0, 0.6);
}

.firearm-detail-card-image img {
  margin-top: 10px;
  width: 80%;
  height: auto;
  object-fit: cover;
  background-color: white;
  border-radius: 10px;
}

.firearm-detail-card-content {
  padding: 5px;
}

.firearm-detail-title {
  font-size: 24px;
  line-height: 1.6;
  margin: 0 0 10px;
  color: antiquewhite;
  text-align: center;
  font-weight: bold;
}

/* Enhancements for the text details */
.firearm-detail-subtitle {
  font-size: 16px;
  line-height: 1.6;
  margin: 0 0 10px;
  color: antiquewhite;
  text-align: center;
}

/* Style for specific details */
.firearm-detail-detail-label {
  font-weight: bold;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .firearm-detail-card-content {
    padding: 15px;
  }

  .firearm-detail-title,
  .firearm-detail-subtitle {
    text-align: left;
  }
}

.button-container .button {
  flex: 1;
  text-align: center;
  margin-right: 5px; /* Adds margin to the right of each button */
}

.button-container .button:last-child {
  margin-right: 0; /* Removes margin from the right of the last button */
}

.firearm-detail-input {
  width: 80%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #34495e;
  border-radius: 4px;
  background-color: #ecf0f1; /* Dark input backgrounds */
  color: #34495e; /* Light text color for readability */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  font-size: medium;
}

.firearm-detail-label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  color: #ecf0f1; /* Light label color for contrast */
}

.firearm-detail-field {
  margin-bottom: 5px;
}

.custom-datepicker {
  font-size: 18px; /* Adjust the font size */
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box; /* Include padding in the width calculation */
  outline: none;
  background-color: #fff;
  margin-bottom: 10px;
}

.custom-datepicker:focus {
  border-color: #007bff; /* Change border color on focus */
  margin-bottom: 10px;
}

.firearm-detail-section {
  margin-bottom: 20px;
  flex: 1; /* Each section takes equal space */
  display: flex;
  flex-direction: column;
}

.firearm-detail-history-box {
  background-color: rgba(0, 10, 0, 0.3);
  padding: 15px;
  border-radius: 8px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;
}

.firearm-detail-title {
  font-size: 18px;
  color: antiquewhite;
  margin-bottom: 10px;
  font-weight: bold;
}

.firearm-detail-content-flex {
  display: flex;
  justify-content: space-between;
}

.firearm-detail-content-left,
.firearm-detail-content-right {
  flex: 1; /* Allows each side to take half of the box's width */
  margin-right: 10px;
}

.firearm-detail-content-right {
  margin-right: 0; /* Removes margin from the right side of the right column */
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(0, 10, 0, 0.3);
  flex-direction: column;
}

.firearm-detail-content-right ul {
  height: 25vh; /* Set a fixed height based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
  padding-right: 10px; /* Optional: to avoid scrollbar hiding content */
}

li {
  list-style: none;
  background-color: rgba(0, 10, 0, 0.5);
  border-radius: 8px;
  color: antiquewhite;
  margin-block: 10px;
}

.firearm-detail-section p {
  color: antiquewhite;
}

li a {
  display: block; /* Makes the entire area clickable */
  padding: 10px; /* Adds some spacing around the text */
  text-decoration: none; /* Removes underline */
  border-radius: 8px;
}

li a:hover {
  background-color: rgba(0, 10, 0, 1);
  border-radius: 8px;
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
}

.firearm-detail-page-right-container {
  position: relative; /* Needed for absolute positioning context */
}

.toggle-button-container {
  position: absolute;
  top: 10px; /* Adjust as necessary for top spacing */
  right: 20px; /* Adjust as necessary for right spacing */
  z-index: 100; /* Ensures the button stays on top of other content */
}

.firearm-detail-gallery-box {
  background-color: rgba(0, 10, 0, 0.3);
  padding: 15px;
  border-radius: 8px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.firearm-detail-gallery-grid {
  min-height: 50%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Creates a responsive grid */
  grid-gap: 10px; /* Space between items */
  padding: 10px;
}

.firearm-detail-gallery-item {
  width: 100%;
  height: auto; /* Maintains aspect ratio */
  border-radius: 8px; /* Optional: Rounds the corners of the images */
  background-color: white;
  align-content: center;
  cursor: pointer;
}

.firearm-detail-gallery-item:hover {
  transform: scale(1.02); /* Slightly enlarges the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  font-weight: bold;
}

.firearm-detail-gallery-add-image-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust size as needed */
  color: #fff; /* Text color */
  background-color: #00000060; /* Button background color, adjust as needed */
  border-radius: 8px; /* Consistent with gallery items */
  cursor: pointer;
}

.firearm-detail-image {
  cursor: pointer;
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  align-items: center;
  align-content: center;
}

/* styles.css */
.detail-label {
  font-weight: bold;
  margin-right: 5px;
  color: wheat;
}

.detail-value {
  color: wheat;
}

.detail-section {
  margin-bottom: 10px;
}

.firearm-detail-date-highlight {
  font-weight: bold; /* Makes text bold */
  color: #007bff; /* Sets the text color to a blue */
}

.firearm-detail-rounds-highlight {
  font-size: 24px; /* Makes text larger */
  font-weight: bold; /* Makes text bold */
  color: #28a745; /* Sets the text color to a green */
}
