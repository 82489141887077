.aes-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index */
}

.aes-form-container {
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent form background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  text-align: center;
}

.aes-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.aes-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.aes-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.aes-form button:hover {
  background-color: #0056b3;
}

.logout-button-link {
  background-color: #dc3545;
}

.logout-button-link:hover {
  background-color: #c82333;
}

.aes-instructions {
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
  text-align: left;
}

.aes-instructions strong {
  color: #333;
}

.aes-error {
  color: red;
  margin-bottom: 10px;
}
