.whats-new-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  padding: 20px;
  color: white;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  position: relative;
}

.page-background.whats-new-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../public/frontpage.png"); /* Path to your background image */
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.whats-new-container {
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  border-radius: 10px;
  z-index: 1000;
  height: 80vh;
  overflow-y: auto; /* Enable vertical scrolling */
}

.whats-new-container h2 {
  font-size: xx-large;
  width: 70vw;
}

.update-item {
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 100%;
}

.update-item h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.update-item p {
  font-size: 1.1rem;
}

.update-item strong {
  color: darksalmon;
}
