.subscription-background-image {
  background-image: url("../../../public/frontpage.png"); /* Path to your background image */
  z-index: 0;
}

.subscription-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: #fff;
}
.message-container {
  color: #fff; /* White text for visibility on dark background */
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  border-radius: 8px; /* Rounded corners for the container */
  padding: 20px; /* Padding inside the container */
  margin: 20px auto; /* Centering the message horizontally */
  text-align: center; /* Center-align the text */
  max-width: 600px; /* Maximum width for better readability */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

.subscriptions-container {
  margin-top: 20px;
  width: 90%; /* Adjust based on your design preference */
  max-width: 800px; /* Maximum width */
  padding: 20px;
  box-sizing: border-box;
}

/* Card styling for each subscription */
.subscription-card {
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
  border-radius: 10px; /* Rounded corners */
  padding: 20px;
  margin-bottom: 20px; /* Space between cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

/* Styling for titles within cards */
.subscription-title {
  font-size: 18px; /* Larger font size for titles */
  margin-bottom: 10px; /* Space below the title */
}

.top-container,
.subscription-status-container,
.subscription-info-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  z-index: 1000;
}
.subscription-status-container {
  max-height: 250px; /* Maximum height before scrolling */
  overflow-y: auto; /* Allows vertical scrolling */
}

.subscription-card,
.option-card {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.canceled-subscription {
  background-color: gray; /* light red background for canceled subscriptions */
}

.subscription-info-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.option-card {
  flex: 1;
  padding: 20px;
  margin: 10px;
  background: #112e12;
  color: #f2fb9c;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.option-card button {
  width: 100%; /* Make the button take the full width of its container */
  border: none;
  border-radius: 4px; /* Maintain rounded corners */
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
  background-color: #4caf50; /* Example button color */
  color: white;
  font-size: 16px;
  display: block; /* Ensures it behaves as a block-level element, taking the full width */
}

.option-card button.disabled {
  background-color: #ccc; /* Grey out disabled buttons */
  color: #666;
  cursor: not-allowed; /* Indicate the button is not clickable */
}

option-card h4 {
  margin-bottom: 10px;
}

.option-card p {
  flex-grow: 1; /* Allows the description to expand */
}

.option-card button {
  width: 100%; /* Button width relative to card */
  padding: 10px;
  margin-top: 10px;
}

.option-card.standard-plan {
  background-color: #112e12; /* Green background for Standard plans */
}

.option-card.pro-plan {
  background-color: #112e12; /* Orange background for Pro plans */
}

.option-card.current-plan {
  border: 5px solid #ffd700; /* Highlight with a gold border */
  box-shadow: 0 4px 8px rgba(255, 215, 0, 0.6); /* Gold glow */
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
}

.unlimited {
  color: antiquewhite;
}

.pro {
  color: gold;
}
.standard {
  color: rgb(4, 158, 4);
}
.free {
  color: gray;
}

.plan-title {
  font-weight: bold;
}
