.modal-content {
  width: auto; /* Adjust based on your needs */
  max-width: 90%; /* Adjust to ensure it fits well in most screens */
  max-height: 70vh; /* Reduced max-height to allow space for buttons */
  overflow-y: auto; /* Allows scrolling within the modal content if necessary */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px; /* Adds space at the bottom inside the modal */
  z-index: 10000;
  background-color: white;
  border-radius: 10px;
}

.focus-modal-image img {
  width: auto; /* Ensures the image is responsive within the content area */
  height: auto; /* Maintains aspect ratio */
  max-height: 50vh; /* Limits image height to leave space for text and buttons */
}

.buttons {
  margin-top: 10px; /* Adds space between the image and the buttons */
  width: 100%; /* Ensures buttons stretch to match the modal content width */
}
