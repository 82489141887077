.home-background-image {
  background-image: url("../../../public/frontpage.png"); /* Path to your background image */
}

.home-left-top {
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Full height of the parent grid */
  background-color: transparent; /* Transparent background */
  z-index: 2; /* Above the overlay */
}

.inventory-link {
  font-size: 18px; /* Adjust font size as needed */
  font-weight: bold;
  color: #fff; /* White text for contrast */
  text-align: center; /* Center align the message */
  padding: 10px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: #4caf50; /* Green background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.2s ease; /* Smooth transition effect */
}

.inventory-link:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.home-left-bottom {
  position: relative;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  border-radius: 8px;
  z-index: 2; /* Above the overlay */
  overflow-y: auto; /* Scroll vertically if content overflows */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.horizontal-scroll-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 15px; /* Space between items */
  overflow-x: auto; /* Enable horizontal scrolling */
  align-items: flex-start; /* Ensure alignment starts from the left/top */
  padding-bottom: 10px; /* Extra padding for scroll visibility */
  height: 100%; /* Full height of the parent section */
}

/* Fixed-width wrapper around FirearmCard */
.firearm-wrapper {
  flex: 0 0 350px; /* Adjust width as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.firearm-wrapper:hover {
  transform: scale(1.05); /* Slight scaling on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deepened shadow */
}

.home-right-top {
  flex: 1; /* Make each section take half the height of the parent */
  display: flex;
  flex-direction: row; /* Arrange horizontally */
  gap: 15px; /* Space between components */
  height: 100%; /* Full height of the section */
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden;
}

/* Map Container Styles */
.home-map-container {
  display: flex;
  flex-direction: column; /* Arrange heading and map vertically */
  justify-content: center; /* Center map vertically */
  align-items: center; /* Center map horizontally */
  padding: 20px;
  flex: 1;
  margin: 0 auto; /* Center the container itself */
  max-width: 800px; /* Limit container width */
  width: 100%; /* Adjust to full width of the screen */
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden; /* Ensure content doesn't spill out */
  cursor: pointer; /* Make it clear the container is clickable */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition effects */
}

.home-map-container:hover {
  transform: scale(1.03); /* Slight zoom effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Deepen shadow on hover */
}

/* Map Title Style */
.home-map-container h2 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
  color: #333; /* Darker color for the title */
  text-align: center;
}

.home-map-image {
  width: 80%; /* Full width of the container */
  max-width: 100%; /* Restrict to the container's width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.home-chart-container {
  flex: 2; /* Adjusts to the available space, making sure it fits well */
  max-width: 66.6%; /* Ensure that it doesn't exceed one-third of the width */
  margin: 0 auto; /* Center it horizontally */
  box-sizing: border-box;
  background-color: rgba(240, 240, 240, 0.9); /* Light background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden; /* Prevent the chart from spilling outside */
  text-align: center; /* Center the title */
}

/* Responsive Chart */
.chart-responsive {
  width: 100%; /* Full width of the parent */
}

.home-right-bottom {
  flex: 1; /* Make each section take half the height of the parent */
  margin: 10px 0;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust background color */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: auto; /* Allow scrolling if the content exceeds the section's height */
  flex-direction: column; /* Stack components vertically */
  justify-content: space-between; /* Ensure both sections take up the full height */
}

.home-right-bottom p {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.home-next-visit-link {
  display: block; /* Ensure it takes the whole width of the container */
  padding: 10px; /* Adjust padding as needed */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep text color consistent */
  cursor: pointer; /* Set cursor to pointer */
  transition: background-color 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
}

/* Hover effect for the next visit link */
.home-next-visit-link:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); /* Deeper shadow on hover */
}

.detail-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent for readability */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  z-index: 2;
}

.home-detail-list {
  width: 100%;
  position: relative; /* Ensure inner content is above the overlay */
  z-index: 2; /* Keeps the elements above the overlay */
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 8px;
  color: #fff; /* Ensure text contrast */
  z-index: 2;
}

.firearm {
  font-weight: bold;
}

.value {
  font-weight: normal;
}

.fixed-help-button {
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 1000;
  width: 30px;
  border-radius: 20px;
}
