.ammo-add-item {
  position: relative; /* Contains the floating form */
}

.ammo-form-container {
  margin-top: 10px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 75%;
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 30%;
  background-color: rgba(0, 0, 0, 0.95);
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 300px;
}

.ammo-form-container select,
.ammo-form-container input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 16px;
}

.ammo-form-container textarea {
  width: 100%;
  height: 120px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 16px;
}

.ammo-custom-datepicker {
  font-size: 18px; /* Adjust the font size */
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box; /* Include padding in the width calculation */
  outline: none;
  background-color: #fff;
  position: relative; /* Ensure position is set to relative */
  z-index: 50; /* Adjust z-index to bring it forward */
  border-color: #007bff; /* Change border color on focus */
  margin-bottom: 10px;
}

.ammo-form-actions {
  display: flex;
  justify-content: space-between;
}

.ammo-form-actions button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #0057e7;
  color: #fff;
  margin-right: 5px;
  z-index: 10;
}

.ammo-form-actions button:last-child {
  background-color: #d9534f; /* Red button for Cancel */
}

.ammo-form-actions button:hover {
  opacity: 0.8; /* Subtle hover effect */
}

.ammo-add-item-trigger-button {
  text-transform: uppercase;
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  display: block; /* Ensure it takes the full width available if necessary */
  width: 100%; /* Adjust width as per design requirement */
  padding: 10px 20px; /* Padding for better touch area */
  margin-top: 20px; /* Space above the button */
  z-index: 2; /* Keeps it above the overlay */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent button background */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  color: #fff; /* Text color */
  cursor: pointer;
}

.ammo-add-item-trigger-button > button {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px; /* Space between the button and the form */
}
